import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {FrbcComponent} from './frbc/frbc.component';
import {FrbcLiveComponent} from './frbc/frbc-live/frbc-live.component';
import {FrbcDespreNoiComponent} from './frbc/frbc-despre-noi/frbc-despre-noi.component';
import {FrbcArticlesComponent} from './frbc/frbc-articles/frbc-articles.component';
import {FrbcSermonsComponent} from './frbc/frbc-sermons/frbc-sermons.component';


const routes: Routes = [
  {
    path: '',
    component: FrbcComponent
  },
  {
    path: 'live',
    component: FrbcLiveComponent
  },
  {
    path: 'despre-noi',
    component: FrbcDespreNoiComponent
  },
  {
    path: 'article/:title',
    component: FrbcArticlesComponent
  },
  {
    path: 'predici',
    component: FrbcSermonsComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
