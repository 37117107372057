import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FrbcAboutUsService} from '../service/frbc-about-us.service';
import {MembersDO} from '../datamodel/members-do';
import {SharedBooleansService} from '../service/shared-booleans.service';

@Component({
  selector: 'app-frbc-despre-noi',
  templateUrl: './frbc-despre-noi.component.html',
  styleUrls: ['./frbc-despre-noi.component.scss']
})
export class FrbcDespreNoiComponent implements OnInit, AfterViewInit {
  members: MembersDO[];
  pastors: MembersDO[];

  constructor(private frbcAboutUsService: FrbcAboutUsService,
              private sharedService: SharedBooleansService) { }

  ngOnInit() {
    this.frbcAboutUsService.getMembers().subscribe(data => {
      this.members = data.comitet;
    });
    this.frbcAboutUsService.getPastors().subscribe(data => {
      this.pastors = data.pastori;
    });
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);

    if (window.screenX === 0 && window.screenY === 0) {
      this.sharedService.setColor.next(false);
    }
  }

}
