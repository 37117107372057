import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FrbcComponent } from './frbc/frbc.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import { FrbcLiveComponent } from './frbc/frbc-live/frbc-live.component';
import { EmbedVideo } from 'ngx-embed-video';
import { FrbcDespreNoiComponent } from './frbc/frbc-despre-noi/frbc-despre-noi.component';
import {
  MatDividerModule,
  MatIconModule,
  MatTooltipModule,
  MatToolbarModule,
  MatMenuModule,
  MatPaginatorModule,
  MatSidenavModule,
  MatList, MatListModule
} from '@angular/material';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {LoadingIndicatorService} from './frbc/service/loading-indicator.service';
import {LoadingIndicatorInterceptor} from './interceptors/loading-indicator-interceptor';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatExpansionModule} from '@angular/material/expansion';
import { FrbcArticlesComponent } from './frbc/frbc-articles/frbc-articles.component';
import {CountdownModule} from 'ngx-countdown';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { FrbcSermonsComponent } from './frbc/frbc-sermons/frbc-sermons.component';
import { FrbcFooterComponent } from './frbc/frbc-footer/frbc-footer.component';
import {FormsModule} from '@angular/forms';
import {DirectivesModule} from './directives/directives.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DatePipe} from '@angular/common';
import {RedirectToHttpsInterceptor} from './interceptors/redirect-to-https-interceptor';

export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    FrbcComponent,
    FrbcLiveComponent,
    FrbcDespreNoiComponent,
    FrbcArticlesComponent,
    FrbcSermonsComponent,
    FrbcFooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatCardModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatDividerModule,
    MatTooltipModule,
    MatToolbarModule,
    MatGridListModule,
    MatExpansionModule,
    MatMenuModule,
    BrowserAnimationsModule,
    NgxAudioPlayerModule,
    ScrollingModule,
    EmbedVideo.forRoot(),
    MatProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatPaginatorModule,
    CountdownModule,
    MatSidenavModule,
    FormsModule,
    DirectivesModule,
    FlexLayoutModule,
    MatListModule,
  ],
  providers: [LoadingIndicatorService, DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: LoadingIndicatorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RedirectToHttpsInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
