import {Directive, ElementRef, HostListener, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[animation], [delay], [duration], [offset], [triggerOnce]'
})
export class AnimateDirective implements OnInit {
  @Input('animation') animationClass = 'fadeInUp';
  @Input() delay = '0';
  @Input() duration;
  @Input('offset') wayPointOffset = '100';
  @Input() triggerOnce = 'false';

  private elObj: any = null;
  private windowH: number;
  private elOffsetTop: number;
  private check = false;
  private oldY = 0;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  @HostListener('window:scroll', ['$event']) onScrollAnimation(event) {
    this.elOffsetTop = this.elObj.getBoundingClientRect().y;

    const wayPoint = this.windowH * Number(this.wayPointOffset) / 60;
    if (this.elOffsetTop > -100 && this.elOffsetTop < wayPoint) {
      this.renderer.removeClass(this.elObj, 'wait-animation');
      this.check = true;
    } else {
      if (this.check) {
        this.renderer.removeClass(this.elObj, 'animated');
        this.renderer.removeClass(this.elObj, this.animationClass);

        if (this.elOffsetTop < this.oldY && this.triggerOnce === 'false') {
          this.renderer.addClass(this.elObj, 'wait-animation');
          this.renderer.addClass(this.elObj, 'animated');
          this.renderer.addClass(this.elObj, this.animationClass);
        }
      }
    }
    this.oldY = this.elOffsetTop;
  }

  @HostListener('window:resize', ['$event']) onResizeWindow(event) {
    this.windowH = window.innerHeight;
  }

  ngOnInit() {
    this.elObj = this.el.nativeElement;
    this.windowH = window.innerHeight;
    this.elOffsetTop = this.elObj.offsetTop;

    if (Number(this.delay) > 0) {
      this.renderer.setStyle(this.elObj, '-webkit-animation-delay', this.delay + 'ms');
      this.renderer.setStyle(this.elObj, 'animation-delay', this.delay + 'ms');
    }

    if (Number(this.duration) > 0) {
      this.renderer.setStyle(this.elObj, '-webkit-animation-duration', this.duration + 'ms');
      this.renderer.setStyle(this.elObj, 'animation-duration', this.duration + 'ms');
    }

    if (this.elOffsetTop > this.windowH * Number(this.wayPointOffset) / 100) {
      this.renderer.addClass(this.elObj, 'wait-animation');
    }
    this.renderer.addClass(this.elObj, 'animated');
    this.renderer.addClass(this.elObj, this.animationClass);
  }
}

