import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {VerseOfTheDayDO} from './datamodel/verse-of-the-day-do';
import {ArticleDO} from './datamodel/article-do';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {FrbcSharedService} from './service/frbc-shared.service';
import {Router} from '@angular/router';
import {SharedBooleansService} from './service/shared-booleans.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-frbc',
  templateUrl: './frbc.component.html',
  styleUrls: ['./frbc.component.scss']
})
export class FrbcComponent implements OnInit, OnDestroy {

  public verseOfTheDay: VerseOfTheDayDO;

  articles: ArticleDO[];

  public safeURL: SafeResourceUrl;
  public safeURL1: SafeResourceUrl;
  public safeURL2: SafeResourceUrl;
  public safeURL3: SafeResourceUrl;
  public safeURL5: SafeResourceUrl;
  public safeURL6: SafeResourceUrl;
  panelOpenState = false;
  youtubeLastSongLink: string;

  @ViewChild('contentHome', {static: true}) contentHome;

  constructor(private frbcHomeService: FrbcSharedService,
              private router: Router,
              private sharedBooleansService: SharedBooleansService,
              private translateService: TranslateService,
              private sanitizer: DomSanitizer
  ) {

  }

  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true);
    // this.frbcHomeService.getVerseOfTheDay().subscribe(verse => {
    //   this.verseOfTheDay = verse;
    // });
    // FIRST SECTION
    this.frbcHomeService.getArticles().subscribe(data => this.articles = data.articles);

    // SECOND SECTION
    this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + 'OLTpBORK1LM');
    this.safeURL1 = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + 'fog5xPYr8xs');
    this.safeURL2 = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + 'SlZZqOFxT9w');
    this.safeURL3 = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + '2jKjtr6qKuQ');

    this.safeURL5 = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + 'uEnqXpeEhE4');

    window.scrollTo(0, 0);
    this.getLastSong();
  }

  getLastSong() {
    this.frbcHomeService.getLastSong().subscribe(lastSong => {
      this.safeURL6 = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + lastSong.items[lastSong.items.length - 1].contentDetails.videoId);
    });
  }

  switchLanguage(language: string) {
    this.translateService.use(language);
  }

  onLastService() {
    this.router.navigate(['/live']);
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scroll, true);
  }

  scroll = (event): void => {
    this.sharedBooleansService.setColor.next(this.contentHome.nativeElement.getBoundingClientRect().y < -200);
  }

  onArticleClick(title: string) {
    this.router.navigate(['article', title]);
  }

  navigateToFRBCradio() {
    window.location.href = 'https://firstromanianradioarizona.com';
  }

  navigateToFRBCvideos() {
    window.location.href = 'https://www.youtube.com/channel/UCrQ4Rb57h1kNwso44Kk1ATQ/videos';
  }

  navigateToYouTube() {
    window.location.href = 'https://facebook.com/frbcarizona/';
  }

  navigateToFacebook() {
    window.location.href = 'https://www.youtube.com/channel/UCrQ4Rb57h1kNwso44Kk1ATQ';
  }
}
