import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import {LoadingIndicatorService} from '../frbc/service/loading-indicator.service';
import {Observable} from 'rxjs';

/**
 * HttpInterceptor that displays the global loading indicator for requests longer than 1 second
 */
@Injectable()
export class LoadingIndicatorInterceptor implements HttpInterceptor {

  constructor(private readonly loadingIndicatorService: LoadingIndicatorService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      this.loadingIndicatorService.showLoadingIndicator.next(true);
      return next.handle(req).pipe(
      finalize(() => {
        setTimeout(() => {
        this.loadingIndicatorService.showLoadingIndicator.next(false);
        }, 500);
      })
    );
  }
}
