import {AfterViewInit, ChangeDetectorRef, Component, OnInit, Sanitizer} from '@angular/core';
import {FrbcSharedService} from '../service/frbc-shared.service';
import {ArticleDO} from '../datamodel/article-do';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, ParamMap, Router} from '@angular/router';
import {SharedBooleansService} from '../service/shared-booleans.service';
import {PrayingListDO} from '../datamodel/praying-list-do';

@Component({
  selector: 'app-frbc-articles',
  templateUrl: './frbc-articles.component.html',
  styleUrls: ['./frbc-articles.component.scss']
})
export class FrbcArticlesComponent implements OnInit, AfterViewInit {

  article: ArticleDO;
  articles: ArticleDO[];

  prayingList: PrayingListDO[];
  articleTitle: string;

  constructor(private frbcShared: FrbcSharedService,
              private sanitizer: DomSanitizer,
              private activeRoute: ActivatedRoute,
              private router: Router,
              private sharedService: SharedBooleansService,
              private cdref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe((params: ParamMap) => {
      this.articleTitle = params.get('title');
    });

    this.frbcShared.getArticles().subscribe(articles => {
      this.articles = articles.articles;
      this.article = articles.articles.find(article => article.title === this.articleTitle);
    });
    if (this.articleTitle === 'roagateimpreunacunoi') {
      this.frbcShared.getPrayingList().subscribe(list => {
        this.prayingList = list.prayingList;
      });
    }
    localStorage.setItem('articles', JSON.stringify(this.articles));
    localStorage.setItem('prayingList', JSON.stringify(this.prayingList));
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
    if (window.screenX === 0 && window.screenY === 0) {
      this.sharedService.setColor.next(false);
    }
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

}
