import {AfterViewInit, ChangeDetectorRef, Component, DoCheck, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoadingIndicatorService} from './frbc/service/loading-indicator.service';
import {delay, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {SharedBooleansService} from './frbc/service/shared-booleans.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, DoCheck {
  title = 'frbc-app';
  loadingIndicator = false;

  @ViewChild('topBar', {static: true}) topBar;

  private readonly unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly loadingIndicatorService: LoadingIndicatorService,
    private translateService: TranslateService,
    public sharedBooleansService: SharedBooleansService,
    public router: Router,
    private cdref: ChangeDetectorRef
  ) {
    translateService.addLangs(['en', 'ro']);
    translateService.setDefaultLang('ro');
    router.events.subscribe(event => {
      // Hack: Scrolls to top of Page after page view initialized
      let top = document.getElementById('topBar');
      if (top !== null) {
        top.scrollIntoView();
        top = null;
      }
    });
  }

  ngOnInit() {
    this.loadingIndicatorService.showLoadingIndicator
      .pipe(takeUntil(this.unsubscribe))
      .pipe(delay(100))
      .subscribe(showLoadingIndicator => (this.loadingIndicator = showLoadingIndicator));
  }


  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngDoCheck(): void {
    this.cdref.detectChanges();
  }

  navigateUp() {
    let top = document.getElementById('topBar');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
}
