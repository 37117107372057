import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, Inject,
  OnDestroy,
  OnInit, Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {FrbcSharedService} from '../service/frbc-shared.service';
import {ServicesDO} from '../datamodel/services-do';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {Observable} from 'rxjs';
import {CountdownComponent} from 'ngx-countdown';
import {SharedBooleansService} from '../service/shared-booleans.service';
import {DatePipe, DOCUMENT} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {IRssItem, NewsRss} from '../datamodel/news-rss';
import {parseString} from 'xml2js';
import {StartDatesDo} from '../datamodel/start-dates-do';

@Component({
  selector: 'app-frbc-live',
  templateUrl: './frbc-live.component.html',
  styleUrls: ['./frbc-live.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FrbcLiveComponent implements OnInit, OnDestroy, AfterViewInit {

  public safeURL: SafeResourceUrl = '';
  public services: ServicesDO[];
  public selectedService: IRssItem;
  public countDownString: string;
  public dateOfService: Date;
  private scriptLink = '//embed.bisericilive.com/get?cid=frbcazcom&tid=blcPlayer1670496099';

  newsRss: NewsRss;


  page = 0;
  size = 4;
  data: ServicesDO[];

  @ViewChild('contentLive', {static: true}) contentLive;

  secondsDiff = 0;
  status = 'ready';
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('countdown', {static: true}) counter: CountdownComponent;
  obs: Observable<any>;
  dataSource: MatTableDataSource<IRssItem>;
  private startDates: StartDatesDo;

  constructor(private frbcHomeService: FrbcSharedService,
              private sanitizer: DomSanitizer,
              private changeDetectorRef: ChangeDetectorRef,
              private sharedService: SharedBooleansService,
              private datePipe: DatePipe,
              private http: HttpClient,
              private renderer2: Renderer2,
              @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit() {
    const linkInit = ' https://embed.bisericilive.com/preview?cid=frbcazcom';
    this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(linkInit);
    this.getStartDates();
    this.getServices();
    window.addEventListener('scroll', this.scroll, true);
    this.getData({pageIndex: this.page, pageSize: this.size});

    // LOAD SCRIPT FOR LIVE PLAYER
    const script = this.renderer2.createElement('script');
    script.type = `text/javascript`;
    script.src = this.scriptLink;
    script.defer = `defer`;
    this.renderer2.appendChild(this.document.body, script);
  }


  // start() {
  //   this.status = 'started';
  //   if (this.counter) {
  //     this.counter.begin();
  //   }
  // }

  scroll = (event): void => {
    this.sharedService.setColor.next(this.contentLive.nativeElement.getBoundingClientRect().y < -200);
  };


  ngAfterViewInit() {

    // Hack: Scrolls to top of Page after page view initialized
    window.scrollTo(0, 0);

    if (window.screenX === 0 && window.screenY === 0) {
      this.sharedService.setColor.next(false);
    }
  }

  setSafeUrl(url: string, selectedService: IRssItem) {
    this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.selectedService = selectedService;

    // LOAD SCRIPT FOR LIVE PLAYER
    const newLink = url.slice(url.indexOf('feat') + 'feat'.length);
    const script = this.renderer2.createElement('script');
    script.type = `text/javascript`;
    script.src = this.scriptLink + '&feat' + newLink;
    script.defer = `defer`;
    this.renderer2.appendChild(this.document.body, script);

  }

  getServices() {
    const requestOptions: any = {
      observe: 'body',
      responseType: 'text'
    };
    this.http
      .get<any>('https://archives.biserici.live/getfeed?cid=frbcazcom', requestOptions)
      .subscribe(data => {
        parseString(data, (err, result: NewsRss) => {
          this.newsRss = result;
        });
        this.dataSource = new MatTableDataSource<IRssItem>(this.newsRss.rss.channel[0].item);
        this.selectedService = this.newsRss.rss.channel[0].item[0];
        this.dateOfService = new Date();
        // tslint:disable-next-line:max-line-length
        this.dataSource = new MatTableDataSource<IRssItem>(this.newsRss.rss.channel[0].item);
        this.changeDetectorRef.detectChanges();
        this.dataSource.paginator = this.paginator;
        this.obs = this.dataSource.connect();
      });
  }

  getData(obj) {
    let index = 0;
    const startingIndex = obj.pageIndex * obj.pageSize;
    const endingIndex = startingIndex + obj.pageSize;

    if (this.services) {
      this.data = this.services.filter(() => {
        index++;
        return (index > startingIndex && index <= endingIndex);
      });
    }
  }

  ngOnDestroy() {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
    window.removeEventListener('scroll', this.scroll, true);
  }

  private getStartDates() {
    const dateInterval = new Date();
    const datePhxInterval = dateInterval.toLocaleDateString('en-EN', {timeZone: 'America/Phoenix'});
    const dayOfWeekInterval = new Date(datePhxInterval).getDay().toLocaleString();
    const date = new Date();
    const phxHour = date.toLocaleString('en-EN', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: 'America/Phoenix'
    });
    this.frbcHomeService.getStartDates().subscribe(dates => {
      this.startDates = dates;
      if (dayOfWeekInterval === this.startDates.serviceDay
        && phxHour >= this.startDates.introductoryHour
        && !(phxHour >= this.startDates.startHour)
        && phxHour <= this.startDates.endHour) {
        this.status = 'incepemincurand';
      } else if (dayOfWeekInterval === this.startDates.serviceDay
        && phxHour >= this.startDates.startHour
        && phxHour <= this.startDates.endHour) {
        this.status = 'suntemlive';
      } else {
        this.status = 'ready';
      }
    });
  }
}
