import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FrbcSharedService} from '../service/frbc-shared.service';
import { Track } from 'ngx-audio-player';
import {SharedBooleansService} from '../service/shared-booleans.service';

@Component({
  selector: 'app-frbc-sermons',
  templateUrl: './frbc-sermons.component.html',
  styleUrls: ['./frbc-sermons.component.scss']
})
export class FrbcSermonsComponent implements OnInit, AfterViewInit {

  @ViewChild('containerSermons', {static: true}) containerSermons;
  msaapDisplayTitle = true;
  msaapDisplayPlayList = true;
  msaapPageSizeOptions = [10];
  msaapDisplayVolumeControls = true;

// Material Style Advance Audio Player Playlist
  msaapPlaylist: Track[] = [];

  constructor(private frbcSharedService: FrbcSharedService, private sharedService: SharedBooleansService) {
  }

  ngOnInit() {
    this.frbcSharedService.getSermons().subscribe(sermons => {
      sermons.sermons.forEach(sermon => {
        this.msaapPlaylist.push(sermon.track);
      });
    });
    window.addEventListener('scroll', this.scroll, true);
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }
  scroll = (event): void => {
    this.sharedService.setColor.next(this.containerSermons.nativeElement.getBoundingClientRect().y < -200);
  };
}
