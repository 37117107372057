import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FrbcAboutUsService {

  constructor(private http: HttpClient) {
  }

  getMembers(): Observable<any> {
    return this.http.get('/assets/json/comitet.json');
  }

  getPastors(): Observable<any>  {
    return this.http.get('/assets/json/pastori.json');
  }
}
